import {
  LOGGED_IN,
  LOG_OUT,
  SET_GIM_AUTH_TOKEN,
  SET_GIM_CONNECTION,
  UPDATE_USER,
  USER_INFO,
} from '../types/user'

const initialState = {
  userInfoTestData: {},
  userInfo: {},
  userRole: '',
  isAdmin: false,
  authToken: '',
  gimAuthToken: '',
  isGimConnected: false,
}

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGGED_IN:
      return {
        userInfo: payload?.UserDetails,
        authToken: payload?.token,
        isAdmin: payload?.Role?.toLowerCase() === 'admin',
        userRole: payload?.Role,
      }
    case UPDATE_USER:
      return {
        ...state,
        userInfo: payload,
        isAdmin: payload?.response?.Role?.toLowerCase() === 'admin',
        userRole: payload?.response?.Role,
      }
    case LOG_OUT:
      return {
        userInfo: {},
        authToken: '',
        isAdmin: false,
        userRole: '',
      }
    case SET_GIM_AUTH_TOKEN:
      return {
        ...state,
        gimAuthToken: payload,
      }
    case SET_GIM_CONNECTION:
      return {
        ...state,
        isGimConnected: payload,
      }
    case USER_INFO:
      return {
        ...state,
        userInfoTestData: payload,
        isAdmin: payload?.Role?.toLowerCase() === 'admin',
      }
    default:
      return state
  }
}

export default authReducer
