export const LOGGED_IN = 'LOGGED_IN'
export const LOG_OUT = 'LOG_OUT'
export const UPDATE_USER = 'UPDATE_USER'
export const PROJECT_LIST = 'PROJECT_LIST'
export const SET_GIM_AUTH_TOKEN = 'SET_GIM_AUTH_TOKEN'
// gim JSON project list
export const GIM_PROJECTS = 'GIM_PROJECTS'
export const FILE_LIST = 'FILE_LIST'
// name of the file to shown into gltf viewer
export const FILE_URL = 'FILE_URL'
export const FILE_TYPES = 'FILE_TYPES'
export const FETCH_FILE_REQUEST = 'FETCH_FILE_REQUEST'
export const FETCH_FILE_RESULT = 'FETCH_FILE_RESULT'
export const ALLOWED_EXTENSIONS = 'ALLOWED_EXTENSIONS'
export const ADD_OBJECT_TO_INPROGRESS = 'ADD_OBJECT_TO_INPROGRESS'
export const REMOVE_OBJECT_FROM_INPROGRESS = 'REMOVE_OBJECT_FROM_INPROGRESS'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const SET_MOBILE_VIEW = 'SET_MOBILE_VIEW'
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS'
export const SET_DOWNLOAD_PROGRESS = 'SET_DOWNLOAD_PROGRESS'
export const SET_HIDE_INSTALL_BUTTON = 'SET_HIDE_INSTALL_BUTTON'

export const SET_CUSTOMIZE_DATA = 'SET_CUSTOMIZE_DATA'
export const SET_IFRAME_STATE = 'SET_IFRAME_STATE'

export const SET_GIM_CONNECTION = 'SET_GIM_CONNECTION'
export const SAVE_JSON = 'SAVE_JSON'
export const GLB_LOAD_STATE = 'GLB_LOAD_STATE'
export const SET_ZOOM_IN_LIMIT = 'SET_ZOOM_IN_LIMIT'

// Test cases
export const INIT_PROJECT_LIST = 'INIT_PROJECT_LIST'
export const C_INPUT_LIST = 'C_INPUT_LIST'
export const USER_INFO = 'USER_INFO'
export const INDUSTRY_DATA = 'INDUSTRY_DATA'
export const INVOICE_LIST = 'INVOICE_LIST'
