import axios from 'axios'
import { toast } from 'react-toastify'

import store from '../../redux/store'
import { EXTERNAL_API, PLUGIN_URL, WORDPRESS_BASE_URL } from './globalConfig'

const storeInstance = store()

const isProduction =
  process.env.REACT_APP_DEVELOPMENT_MODE === 'development' ? false : true

const CancelToken = axios.CancelToken
export const cancelSource = CancelToken.source()

const requestInterceptor = [
  (request) => {
    request.cancelToken = cancelSource.token
    // req.headers.authorization = 'my secret token';
    return request
  },
  (error) => console.error(error),
]

const responseInterceptor = [
  (response) => {
    return response
  },
  (error) => {
    console.error(error)
    if (error?.response?.data?.Message) {
      toast.error(error.response.data.Message || 'Oops! something went wrong')
    } else if (error.message?.toLowerCase() === 'network error') {
      toast.error('Oops! something went wrong')
    } else if (error.message !== 'Request failed with status code 404') {
      toast.error(error.message || 'Oops! something went wrong')
    }
    return Promise.reject(error)
  },
]

const responseLocalInterceptor = [
  (response) => {
    return response
  },
  (error) => {
    console.log('error>>>>', error, error?.message)
    console.error(error)
    if (error?.response?.data?.Message) {
      toast.error(error.response.data.Message || 'Oops! something went wrong')
    } else if (error.message?.toLowerCase() === 'network error') {
      toast.error('Please install or restart GIMmanager')
    } else if (error.message !== 'Request failed with status code 404') {
      toast.error(error.message || 'Oops! something went wrong')
    }
    return Promise.reject(error)
  },
]

const expternalApi = axios.create({
  baseURL: EXTERNAL_API,
  paramsSerializer: { 
    indexes: null
  }
})

const localApi = axios.create({
  baseURL: PLUGIN_URL,
  headers: {
    isProduction,
  },
})

const wpApi = axios.create({
  baseURL: WORDPRESS_BASE_URL,
})

const listner = () => {
  const { authToken, gimAuthToken, userLanguage } =
    storeInstance.getState().user
  localApi.defaults.headers.common.Authorization = gimAuthToken
    ? `Bearer ${gimAuthToken}`
    : null
  expternalApi.defaults.headers.common.Authorization = authToken
    ? `JWT ${authToken}`
    : null
  expternalApi.defaults.headers.common.Language = userLanguage ?? 'en'
}

storeInstance.subscribe(listner)

expternalApi.interceptors.request.use(...requestInterceptor)
expternalApi.interceptors.response.use(...responseInterceptor)

localApi.interceptors.request.use(...requestInterceptor)
localApi.interceptors.response.use(...responseLocalInterceptor)

wpApi.interceptors.request.use(...requestInterceptor)
wpApi.interceptors.response.use(...responseInterceptor)

export { localApi, expternalApi, wpApi }
