import React, { useCallback, useContext, useEffect, useState } from 'react'

import {
  faChevronDown,
  faDownload,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import Headroom from 'react-headroom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link, useLocation } from 'react-router-dom'

import { AppContext } from '../../App'
import DeFlag from '../../assets/de.svg'
import EnFlag from '../../assets/en-gb.svg'
import FrFlag from '../../assets/fr.svg'
import ItFlag from '../../assets/it.svg'
import logo from '../../assets/logo.png'
import NlFlag from '../../assets/nl.svg'
import gimLogoWhite from '../../assets/transparent-logo-white.png'
import gimLogoYellow from '../../assets/transparent-logo-yellow.png'
import {
  changeLanuage,
  setHideInstallButton,
  userSignOut,
} from '../../redux/actions/user/user.actions'
import {
  userIdSelector,
  userInfoSelector,
} from '../../redux/selectors/user.selector'
import { routePaths } from '../../shared/config/globalConfig'
import { downloadURI } from '../../shared/config/globalFunctions'
import MyVerticallyCenteredModal from '../InstallGimManagerModal'
import Signin from './Signin.jsx'
import './Header.scss'

const Header = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const userInfo = useSelector(userInfoSelector)
  const { isAdmin } = useSelector((state) => state?.auth)
  const { hideInstallButton, isMobileView } = useSelector(
    (state) => state?.user
  )

  const userid = useSelector(userIdSelector)
  const [modalShow, setModalShow] = useState(false)
  const { appContext, setAppContext } = useContext(AppContext)
  const [adminRoutes] = useState([routePaths.invoiceList])
  const [hoverState, setHoverState] = useState('')
  const [languageOptions] = useState([
    {
      value: 'en',
      label: (
        <div>
          <img src={EnFlag} width="20px" height="20px" alt="" /> <div>EN</div>
        </div>
      ),
    },
    {
      value: 'nl',
      label: (
        <div>
          <img src={NlFlag} width="20px" height="20px" alt="" /> <div>NL</div>
        </div>
      ),
    },
    {
      value: 'de',
      label: (
        <div>
          <img src={DeFlag} width="20px" height="20px" alt="" /> <div>DE</div>
        </div>
      ),
    },
    {
      value: 'it',
      label: (
        <div>
          <img src={ItFlag} width="20px" height="20px" alt="" /> <div>IT</div>
        </div>
      ),
    },
    {
      value: 'fr',
      label: (
        <div>
          <img src={FrFlag} width="20px" height="20px" alt="" /> <div>FR</div>
        </div>
      ),
    },
  ])
  const [AdminLanguageOptions] = useState([
    {
      value: 'en',
      label: (
        <div>
          <img src={EnFlag} width="20px" height="20px" alt="" /> <div>EN</div>
        </div>
      ),
    },
    {
      value: 'nl',
      label: (
        <div>
          <img src={NlFlag} width="20px" height="20px" alt="" /> <div>NL</div>
        </div>
      ),
    },
  ])

  const logoutLinkedin = (url) => {
    // Invisible window popup
    const win = window.open(
      url,
      '_blank',
      'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none'
    )
    setTimeout(() => {
      win.close()
      return
    }, 3000)
  }

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name)
      })
    })
  }

  const handleSignout = () => {
    setAppContext((prevContext) => ({ ...prevContext, showAppLoader: true }))
    dispatch(userSignOut())
    logoutLinkedin('https://linkedin.com/m/logout') //This will logs out user from linked in profile
    sessionStorage.clear()
    localStorage.clear()
    clearCacheData()
    setTimeout(() => {
      setAppContext((prevContext) => ({ ...prevContext, showAppLoader: false }))
      // window.location.reload()
    }, 500)
  }

  const changeLanguageHandler = useCallback(
    (e) => {
      const languageValue = e?.value || e?.target?.value
      i18n.changeLanguage(languageValue)
      dispatch(changeLanuage(languageValue))
    },
    [dispatch, i18n]
  )

  React.useEffect(() => {
    if (adminRoutes?.indexOf(location.pathname) > -1) {
      if (i18n.language === 'en' || i18n.language === 'nl') {
      } else {
        changeLanguageHandler(languageOptions[0])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminRoutes, location.pathname])

  const renderLanguages = useCallback(() => {
    let options =
      adminRoutes?.indexOf(location.pathname) > -1
        ? AdminLanguageOptions
        : languageOptions
    return options.map((ele, i) => (
      <div
        key={i}
        className={`language-section ${
          i18n.language === ele?.value && 'active-language'
        }`}
        onClick={() => {
          changeLanguageHandler(ele)
          document.body.click()
        }}
      >
        {ele?.label}
      </div>
    ))
  }, [
    AdminLanguageOptions,
    adminRoutes,
    changeLanguageHandler,
    i18n.language,
    languageOptions,
    location.pathname,
  ])

  useEffect(() => {
    document.body.click()
  }, [hoverState])

  const UserMenu = () => {
    return (
      <div
        className="user-profile-head"
        style={{ margin: userInfo?.profilePicUrl && '0px 35px' }}
        onMouseEnter={() => onHoverView('MyProject')}
        onMouseLeave={() => onHoverView('')}
        onClick={() =>
          !isMobileView &&
          history.push({
            pathname: `${routePaths.projects}`,
          })
        }
      >
        {userInfo?.profilePicture ? (
          <Image
            src={userInfo?.profilePicture}
            alt=""
            height={30}
            width={30}
            roundedCircle
          />
        ) : (
          <span
            class="dot"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {`${userInfo?.localizedFirstName?.charAt(
              0
            )}${userInfo?.localizedLastName?.charAt(0)}`}
          </span>
        )}
      </div>
    )
  }

  const RemoveElement = useCallback(
    (type) => {
      if (!isMobileView) {
        setTimeout(() => {
          const element = document.querySelector('.dropdown-menu.show')
          if (element && window.innerWidth <= 992) {
            element.classList.remove('show')
          }
        }, 100)
      }
    },
    [isMobileView]
  )

  const onHoverView = useCallback(
    (type) => {
      if (!isMobileView) {
        setHoverState(type)
      }
    },
    [isMobileView]
  )

  return (
    <Headroom
      className={`hide-nav ${
        (location.pathname?.includes(routePaths.proInfo) ||
          location.pathname?.includes(routePaths.transferApp) ||
          location.pathname?.includes(routePaths.storeInfo) ||
          location.pathname?.includes(routePaths.mapsInfo)) &&
        !location?.pathname?.includes(routePaths?.projects)
          ? ''
          : 'app-nav'
      }`}
      // className={
      //   location.pathname?.includes(routePaths.proApp) ||
      //   location.pathname?.includes(routePaths.storeApp) ||
      //   location.pathname?.includes(routePaths.mapsApp)
      //     ? location?.pathname?.includes(routePaths?.projects)
      //       ? 'project-header'
      //       : 'pro hide-nav'
      //     : 'hide-nav'
      // }
    >
      <Navbar className="header-nav" expand="lg" collapseOnSelect={true}>
        {process.env.REACT_APP_DEVELOPMENT_MODE !== 'production' ? (
          <Navbar.Brand
            className={
              (location.pathname.includes(routePaths.proInfo) ||
                location.pathname.includes(routePaths.storeInfo)) &&
              location.pathname !== routePaths?.projects
                ? 'pro-brand'
                : 'brand'
            }
            eventKey="1"
            data-testid="rootLink"
            as={Link}
            to={`${routePaths.root}`}
          >
            <img src={logo} className="d-inline-block align-top" alt="Logo" />
          </Navbar.Brand>
        ) : (
          <NavDropdown
            renderMenuOnMount={true}
            id="GimBrand"
            className={`text-center header-menu bran-menu ${
              hoverState === 'General' ||
              (location.pathname === routePaths?.root && !isMobileView)
                ? 'first-child-active'
                : ''
            }`}
            onMouseEnter={() => onHoverView('General')}
            onMouseLeave={() => onHoverView('')}
            onClick={() => RemoveElement()}
            data-testid="navDropdownBrand"
            title={
              // <Nav.Link
              //   id="GimStore1"
              //   data-testid="storeButton"
              //   // as={Link}
              //   // to={`${routePaths.store}`}
              //   className="items logo-items"
              //   active={location.pathname.includes(routePaths.storeInfo)}
              //   style={{ padding: '0px 0.5rem' }}
              // >
              <Navbar.Brand
                className={
                  (location.pathname.includes(routePaths.proInfo) ||
                    location.pathname.includes(routePaths.storeInfo)) &&
                  location.pathname !== routePaths?.projects
                    ? 'pro-brand'
                    : 'brand'
                }
                eventKey="1"
                data-testid="rootLink"
                as={!isMobileView ? Link : 'span'}
                to={`${routePaths.root}`}
                onMouseEnter={() => onHoverView('General')}
                onMouseLeave={() => onHoverView('')}
              >
                <img
                  src={logo}
                  className="d-inline-block align-top"
                  alt="Logo"
                />
              </Navbar.Brand>
              // </Nav.Link>
            }
          >
            <NavDropdown.Item
              id="GIMBrand-General"
              className={
                hoverState === 'General' ||
                location.pathname === routePaths?.root
                  ? 'active-header-link'
                  : ''
              }
              as={Link}
              to={`${routePaths.root}`}
              eventKey="11"
              onMouseEnter={() => onHoverView('General')}
              onMouseLeave={() => onHoverView('')}
            >
              {t('Header.general')}
            </NavDropdown.Item>
            <NavDropdown.Item
              id="GIMBrand-About-us"
              className={
                location.pathname === routePaths?.aboutUs &&
                'active-header-link'
              }
              as={Link}
              to={`${routePaths.aboutUs}`}
              eventKey="12"
            >
              {t('Header.aboutUs')}
            </NavDropdown.Item>
            <NavDropdown.Item
              id="GIMBrand-werkenBijGim"
              className={
                location.pathname === routePaths?.werkenBijGim &&
                'active-header-link'
              }
              as={Link}
              to={`${routePaths.werkenBijGim}`}
              eventKey="13"
            >
              {t('Header.werkenBijGim')}
            </NavDropdown.Item>
            <NavDropdown.Item
              id="GIMBrand-Contact"
              className={
                location.pathname === routePaths?.contact &&
                'active-header-link'
              }
              as={Link}
              to={`${routePaths.contact}`}
              eventKey="14"
            >
              {t('Header.contact')}
            </NavDropdown.Item>
            <NavDropdown.Item
              id="GIMBrand-FAQs"
              className={
                location.pathname === routePaths?.faqs && 'active-header-link'
              }
              as={Link}
              to={`${routePaths.faqs}`}
              eventKey="15"
            >
              {t('Header.faqs')}
            </NavDropdown.Item>
            <NavDropdown.Item
              id="GIMBrand-Privacy-Policy"
              className={
                location.pathname === routePaths?.privacyPolicy &&
                'active-header-link'
              }
              as={Link}
              to={`${routePaths.privacyPolicy}`}
              eventKey="16"
            >
              {t('Header.termsAndConditions')}
            </NavDropdown.Item>
          </NavDropdown>
        )}

        {location.pathname !== routePaths.gim ? (
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto pro-nav">
              {/* <Nav.Link
                href={routePaths.home}
                {...(location.pathname === routePaths.root ||
                location.pathname === routePaths.home
                  ? { active: true }
                  : {})}
                className="items"
                data-testid="homeLink"
              >
                {t('Header.home')}
              </Nav.Link> */}

              {/* <Nav.Link
                href={routePaths.solution}
                className="items"
                {...([
                  routePaths.solution,
                  routePaths.solutionDemo,
                  routePaths.solutionPreFund,
                ].includes(location.pathname)
                  ? { active: true }
                  : {})}
              >
                Solutions
              </Nav.Link>

              <Nav.Link
                href={routePaths.customers}
                className="items"
                {...(location.pathname === routePaths.customers
                  ? { active: true }
                  : {})}
              >
                Customers
              </Nav.Link> */}
              {/* {process.env.REACT_APP_DEVELOPMENT_MODE !== 'production' && ( */}
              <Nav.Link
                id="GimTransfer"
                data-testid="storeButton"
                eventKey="4"
                as={Link}
                to={`${routePaths.transferApp}`}
                className="items logo-items"
                active={
                  location.pathname.includes(routePaths.transferApp) ||
                  hoverState === 'app'
                }
              >
                <img
                  className="gim-logo"
                  style={{ width: '40px', marginBottom: '4px' }}
                  src={
                    location.pathname.includes(routePaths.transferApp) ||
                    hoverState === 'app'
                      ? gimLogoYellow
                      : gimLogoWhite
                  }
                  alt=""
                />
                transfer
              </Nav.Link>
              {/* )} */}
              {process.env.REACT_APP_DEVELOPMENT_MODE !== 'production' ? (
                <Nav.Link
                  id="GimStore"
                  data-testid="storeButton"
                  eventKey="3"
                  as={Link}
                  to={`${routePaths.storeApp}`}
                  className="items logo-items"
                  active={
                    location.pathname.includes(routePaths.storeApp) ||
                    hoverState === 'store'
                  }
                  // {...(location.pathname.includes(routePaths.store)
                  //   ? { active: true }
                  //   : {})}
                  // onMouseEnter={() => setHoverState('store')}
                  // onMouseLeave={() => setHoverState('')}
                >
                  <img
                    className="gim-logo"
                    style={{ width: '40px', marginBottom: '4px' }}
                    src={
                      location.pathname.includes(routePaths.storeApp) ||
                      hoverState === 'store'
                        ? gimLogoYellow
                        : gimLogoWhite
                    }
                    alt=""
                  />
                  store
                </Nav.Link>
              ) : (
                <NavDropdown
                  renderMenuOnMount={true}
                  id="GimStore"
                  className={`text-center header-menu ${
                    hoverState === 'StoreInformation' ||
                    (location.pathname === routePaths?.storeInfo &&
                      !isMobileView)
                      ? 'first-child-active'
                      : ''
                  }`}
                  data-testid="navDropdownStore"
                  title={
                    isMobileView ? (
                      <div className="mobiMenuItem" eventKey="2">
                        <div
                          className={`text ${
                            location.pathname.includes(routePaths.storeInfo)
                              ? 'active'
                              : ''
                          }`}
                          // onClick={() => {
                          //   RemoveElement('link')
                          //   history.push({
                          //     pathname: `${routePaths.storeInfo}`,
                          //   })
                          // }}
                        >
                          <img
                            className="gim-logo"
                            style={{ width: '40px', marginBottom: '4px' }}
                            src={
                              location.pathname.includes(
                                routePaths.storeInfo
                              ) || hoverState === 'store'
                                ? gimLogoYellow
                                : gimLogoWhite
                            }
                            alt=""
                          />
                          store
                        </div>

                        <span className="icon">
                          <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                      </div>
                    ) : (
                      <Nav.Link
                        id="GimStore1"
                        data-testid="storeButton"
                        as={!isMobileView ? Link : 'span'}
                        to={`${routePaths.storeInfo}`}
                        className="items logo-items"
                        // className={`items logo-items ${
                        //   hoverState === 'StoreInformation'
                        //     ? 'active-header-link'
                        //     : ''
                        // }`}
                        onMouseEnter={() => onHoverView('StoreInformation')}
                        onMouseLeave={() => onHoverView('')}
                        active={location.pathname.includes(
                          routePaths.storeInfo
                        )}
                        onClick={() => RemoveElement()}
                      >
                        <img
                          className="gim-logo"
                          style={{ width: '40px', marginBottom: '4px' }}
                          src={
                            location.pathname.includes(routePaths.storeInfo) ||
                            hoverState === 'store'
                              ? gimLogoYellow
                              : gimLogoWhite
                          }
                          alt=""
                        />
                        store
                        {isMobileView && (
                          <span>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </span>
                        )}
                      </Nav.Link>
                    )
                  }
                >
                  <NavDropdown.Item
                    id="GIMStore-Info"
                    className={
                      location.pathname === routePaths?.storeInfo ||
                      hoverState === 'StoreInformation'
                        ? 'active-header-link'
                        : ''
                    }
                    as={Link}
                    to={`${routePaths.storeInfo}`}
                    eventKey="21"
                    onMouseEnter={() => onHoverView('StoreInformation')}
                    onMouseLeave={() => onHoverView('')}
                  >
                    {t('Header.information')}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    id="GIMStore-Tech"
                    className={
                      location.pathname === routePaths?.storeTech &&
                      'active-header-link'
                    }
                    as={Link}
                    to={`${routePaths.storeTech}`}
                    eventKey="22"
                    active={location.pathname === routePaths?.storeTech}
                  >
                    {t('Header.technology')}
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    id="GIMStore-App"
                    className={
                      location.pathname === routePaths?.storeApp &&
                      'active-header-link'
                    }
                    as={Link}
                    to={`${routePaths.storeApp}`}
                    eventKey="23"
                    active={location.pathname === routePaths?.storeApp}
                  >
                    {t('Header.startApplication')}
                  </NavDropdown.Item>
                </NavDropdown>
              )}
              {/* <OverlayTrigger
                trigger={appContext?.showIntro ? 'click' : 'focus'}
                // trigger="click"
                key="bottom-store"
                placement="bottom"
                overlay={
                  <Popover id="popover-positioned-bottom">
                    <Popover.Content className="header-menu">
                      <NavDropdown.Item
                        id="GIMStore-Info"
                        className={
                          location.pathname === routePaths?.storeInfo &&
                          'active-header-link'
                        }
                        as={Link}
                        to={`${routePaths.storeInfo}`}
                      >
                        Info
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        id="GIMStore-Tech"
                        className={
                          location.pathname === routePaths?.storeTech &&
                          'active-header-link'
                        }
                        as={Link}
                        to={`${routePaths.storeTech}`}
                      >
                        Tech
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        id="GIMStore-App"
                        className={
                          location.pathname === routePaths?.storeApp &&
                          'active-header-link'
                        }
                        as={Link}
                        to={`${routePaths.storeApp}`}
                      >
                        App
                      </NavDropdown.Item>
                    </Popover.Content>
                  </Popover>
                }
              >
                <Nav.Link
                  id="GimStore"
                  data-testid="storeButton"
                  eventKey="2"
                  // as={Link}
                  // to={`${routePaths.store}`}
                  className="items logo-items"
                  active={location.pathname.includes(routePaths.storeInfo)}
                >
                  <img
                    className="gim-logo"
                    style={{ width: '40px', marginBottom: '4px' }}
                    src={
                      location.pathname.includes(routePaths.storeInfo) ||
                      hoverState === 'store'
                        ? gimLogoYellow
                        : gimLogoWhite
                    }
                    alt=""
                  />
                  store
                </Nav.Link>
              </OverlayTrigger> */}

              {/* <Nav.Link
                href={routePaths.blog}
                target="_blank"
                className="items"
              >
                Blog
              </Nav.Link> */}
              {/* {process.env.NODE_ENV === 'development' && ( */}
              <React.Fragment>
                {/* <Nav.Link
                  href={routePaths.gim}
                  className="items logo-items"
                  {...(location.pathname === routePaths.gim
                    ? { active: true }
                    : {})}
                >
                  <img className="gim-logo" src={gimLogo} alt="" />
                  transfer
                </Nav.Link> */}

                {/* Menu options */}
                {process.env.REACT_APP_DEVELOPMENT_MODE !== 'production' ? (
                  <Nav.Link
                    id="GimPro"
                    data-testid="proLink"
                    eventKey="2"
                    as={Link}
                    to={`${routePaths.proApp}`}
                    className="items"
                    active={
                      (location.pathname.includes(routePaths.proApp) &&
                        location.pathname !== routePaths?.projects) ||
                      hoverState === 'pro'
                    }
                    // onMouseEnter={() => setHoverState('pro')}
                    // onMouseLeave={() => setHoverState('')}
                    // {...(location.pathname.includes(routePaths.pro) &&
                    // location.pathname !== routePaths?.projects
                    //   ? { active: true }
                    //   : {})}
                  >
                    <img
                      className="gim-logo"
                      style={{ width: '40px', marginBottom: '4px' }}
                      src={
                        (location.pathname.includes(routePaths.proApp) &&
                          location.pathname !== routePaths?.projects) ||
                        hoverState === 'pro'
                          ? gimLogoYellow
                          : gimLogoWhite
                      }
                      alt=""
                    />
                    pro
                  </Nav.Link>
                ) : (
                  <NavDropdown
                    renderMenuOnMount={true}
                    id="GimPro"
                    data-testid="proButton"
                    className={`text-center header-menu ${
                      hoverState === 'ProInformation' ||
                      (location.pathname === routePaths?.proInfo &&
                        !isMobileView)
                        ? 'first-child-active'
                        : ''
                    }`}
                    title={
                      isMobileView ? (
                        <div className="mobiMenuItem">
                          <div
                            className={`text ${
                              location.pathname.includes(routePaths.proInfo) &&
                              location.pathname !== routePaths.projects
                                ? 'active'
                                : ''
                            }`}
                            // onClick={() => {
                            //   RemoveElement('link')
                            //   history.push({
                            //     pathname: `${routePaths.proInfo}`,
                            //   })
                            // }}
                          >
                            <img
                              className="gim-logo"
                              style={{ width: '40px', marginBottom: '4px' }}
                              src={
                                (location.pathname.includes(
                                  routePaths.proInfo
                                ) &&
                                  location.pathname !== routePaths.projects) ||
                                hoverState === 'pro'
                                  ? gimLogoYellow
                                  : gimLogoWhite
                              }
                              alt=""
                            />
                            pro
                          </div>

                          <span className="icon">
                            <FontAwesomeIcon icon={faChevronDown} />
                          </span>
                        </div>
                      ) : (
                        <Nav.Link
                          id="GimPro1"
                          data-testid="proButton"
                          eventKey="3"
                          as={!isMobileView ? Link : 'span'}
                          to={`${routePaths.proInfo}`}
                          className="items logo-items"
                          // className={`items logo-items ${
                          //   hoverState === 'ProInformation'
                          //     ? 'active-header-link'
                          //     : ''
                          // }`}
                          onClick={() => RemoveElement()}
                          onMouseEnter={() => onHoverView('ProInformation')}
                          onMouseLeave={() => onHoverView('')}
                          active={
                            location.pathname.includes(routePaths.proInfo) &&
                            location.pathname !== routePaths.projects
                          }
                        >
                          <img
                            className="gim-logo"
                            style={{ width: '40px', marginBottom: '4px' }}
                            src={
                              location.pathname.includes(routePaths.proInfo) &&
                              location.pathname !== routePaths.projects
                                ? gimLogoYellow
                                : gimLogoWhite
                            }
                            alt=""
                          />
                          pro
                          {isMobileView && (
                            <span>
                              <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                          )}
                        </Nav.Link>
                      )
                    }
                  >
                    <NavDropdown.Item
                      id="GIMPro-Info"
                      className={
                        location.pathname === routePaths?.proInfo ||
                        hoverState === 'ProInformation'
                          ? 'active-header-link'
                          : ''
                      }
                      as={Link}
                      to={`${routePaths.proInfo}`}
                      eventKey="31"
                      onMouseEnter={() => onHoverView('ProInformation')}
                      onMouseLeave={() => onHoverView('')}
                    >
                      {t('Header.information')}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      id="GIMPro-Tech"
                      className={
                        location.pathname === routePaths?.proTech &&
                        'active-header-link'
                      }
                      onMouseEnter={() => onHoverView('ProTechnology')}
                      onMouseLeave={() => onHoverView('')}
                      as={Link}
                      to={`${routePaths.proTech}`}
                      eventKey="32"
                    >
                      {t('Header.technology')}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      id="GIMPro-App"
                      className={
                        location.pathname === routePaths?.proApp &&
                        'active-header-link'
                      }
                      onMouseEnter={() => onHoverView('ProApplication')}
                      onMouseLeave={() => onHoverView('')}
                      as={Link}
                      to={`${routePaths.proApp}`}
                      eventKey="33"
                    >
                      {t('Header.startApplication')}
                    </NavDropdown.Item>
                  </NavDropdown>
                )}

                {process.env.REACT_APP_DEVELOPMENT_MODE !== 'production' ? (
                  <Nav.Link
                    id="GimPro"
                    data-testid="proLink"
                    eventKey="3"
                    as={Link}
                    to={`${routePaths.mapsApp}`}
                    className="items"
                    active={location.pathname.includes(routePaths.mapsApp)}
                  >
                    <img
                      className="gim-logo"
                      style={{ width: '40px', marginBottom: '4px' }}
                      src={
                        location.pathname.includes(routePaths.mapsApp)
                          ? gimLogoYellow
                          : gimLogoWhite
                      }
                      alt=""
                    />
                    maps
                  </Nav.Link>
                ) : (
                  <NavDropdown
                    renderMenuOnMount={true}
                    id="GimMaps"
                    data-testid="proButton"
                    className={`text-center header-menu ${
                      hoverState === 'MapInformation' ||
                      (location.pathname === routePaths?.mapsInfo &&
                        !isMobileView)
                        ? 'first-child-active'
                        : ''
                    }`}
                    title={
                      isMobileView ? (
                        <div className="mobiMenuItem">
                          <div
                            className={`text ${
                              location.pathname.includes(routePaths.mapsInfo)
                                ? 'active'
                                : ''
                            }`}
                            // onClick={() => {
                            //   RemoveElement('link')
                            //   history.push({
                            //     pathname: `${routePaths.mapsInfo}`,
                            //   })
                            // }}
                          >
                            <img
                              className="gim-logo"
                              style={{ width: '40px', marginBottom: '4px' }}
                              src={
                                location.pathname.includes(
                                  routePaths.mapsInfo
                                ) || hoverState === 'maps'
                                  ? gimLogoYellow
                                  : gimLogoWhite
                              }
                              alt=""
                            />
                            maps
                          </div>

                          <span className="icon">
                            <FontAwesomeIcon icon={faChevronDown} />
                          </span>
                        </div>
                      ) : (
                        <Nav.Link
                          id="GimMaps1"
                          data-testid="mapsButton"
                          as={!isMobileView ? Link : 'div'}
                          to={`${routePaths.mapsInfo}`}
                          // className="items logo-items"
                          className="items logo-items"
                          // className={`items logo-items ${
                          //   hoverState === 'MapInformation'
                          //     ? 'active-header-link'
                          //     : ''
                          // }`}
                          onClick={() => RemoveElement()}
                          onMouseEnter={() => onHoverView('MapInformation')}
                          onMouseLeave={() => onHoverView('')}
                          active={location.pathname.includes(
                            routePaths.mapsInfo
                          )}
                        >
                          <img
                            className="gim-logo"
                            style={{ width: '40px', marginBottom: '4px' }}
                            src={
                              location.pathname.includes(routePaths.mapsInfo)
                                ? gimLogoYellow
                                : gimLogoWhite
                            }
                            alt=""
                          />
                          maps
                          {isMobileView && (
                            <span>
                              <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                          )}
                        </Nav.Link>
                      )
                    }
                  >
                    <NavDropdown.Item
                      id="GIMMap-Info"
                      className={
                        location.pathname === routePaths?.mapsInfo ||
                        hoverState === 'MapInformation'
                          ? 'active-header-link'
                          : ''
                      }
                      onMouseEnter={() => onHoverView('MapInformation')}
                      onMouseLeave={() => onHoverView('')}
                      as={Link}
                      to={`${routePaths.mapsInfo}`}
                      eventKey="41"
                    >
                      {t('Header.information')}
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      id="GIMMap-Tech"
                      className={
                        location.pathname === routePaths?.mapsTech &&
                        'active-header-link'
                      }
                      onMouseEnter={() => onHoverView('MapTechnology')}
                      onMouseLeave={() => onHoverView('')}
                      as={Link}
                      to={`${routePaths.mapsTech}`}
                      eventKey="42"
                      active={location.pathname === routePaths?.mapsTech}
                    >
                      {t('Header.technology')}
                    </NavDropdown.Item>

                    <NavDropdown.Item
                      id="GIMMap-App"
                      className={
                        location.pathname === routePaths?.mapsApp &&
                        'active-header-link'
                      }
                      onMouseEnter={() => onHoverView('MapApplication')}
                      onMouseLeave={() => onHoverView('')}
                      as={Link}
                      to={`${routePaths.mapsApp}`}
                      eventKey="43"
                      active={location.pathname === routePaths?.mapsApp}
                    >
                      {t('Header.startApplication')}
                    </NavDropdown.Item>
                  </NavDropdown>
                )}

                {/* <Nav.Link
                  href={routePaths.safe}
                  className="items logo-items"
                  {...(location.pathname === routePaths.safe
                    ? { active: true }
                    : {})}
                >
                  <img
                    className="gim-logo"
                    style={{ width: '40px' }}
                    src={
                      location.pathname === routePaths.safe
                        ? gimLogoYellow
                        : gimLogoWhite
                    }
                    alt=""
                  />
                  safe
                </Nav.Link> */}
              </React.Fragment>
              {/* )} */}

              {[`${routePaths.proApp}`, `${routePaths.storeApp}`].includes(
                location.pathname
              ) && (
                // !!!Object.keys(userInfo).length &&
                <Button
                  className="ml-lg-4 mt-4 mt-lg-0"
                  onClick={() =>
                    setAppContext({ ...appContext, showIntro: true })
                  }
                >
                  {t('Header.takeATour')}
                </Button>
              )}

              {/*<Nav.Link
                href={routePaths.downloadDesktop}
                className="items"
                {...(location.pathname === routePaths.downloadDesktop
                  ? { active: true }
                  : {})}
              >
                Desktop App
              </Nav.Link>*/}
              {/*<NavLink to="/GIMtransfer" replace activeClassName="active-class">
              <Nav.Link href="/GIMtransfer" className="items">
                GIMtransfer
              </Nav.Link>
  </NavLink>*/}
              {/*<NavLink to="/GIMsafe" replace activeClassName="active-class">
              <Nav.Link href="/GIMsafe" className="items">
                GIMsafe
              </Nav.Link>
  </NavLink>*/}
            </Nav>

            {[`${routePaths.proApp}`, `${routePaths.storeApp}`].includes(
              location.pathname
            ) && !!!Object.keys(userInfo).length ? (
              <div className="sign-in-text">
                {t('Header.pleaseSignInToSeeMoreProjects')}
              </div>
            ) : null}
          </Navbar.Collapse>
        ) : null}

        <div
          className="justify-content-end d-flex headRightBar"
          data-testid="NavCollapse"
        >
          <>
            {appContext?.showInstallerButton &&
            !hideInstallButton &&
            [
              `${routePaths.proApp}`,
              `${routePaths.storeApp}`,
              `${routePaths.mapsApp}`,
              `${routePaths.transferApp}`,
              `${routePaths.projects}`,
            ].includes(location.pathname) ? (
              <Button
                id="InstallGIMmanager"
                className="download-exe flex-shrink-0"
                variant="secondary"
                onClick={() => setModalShow(true)}
              >
                <FontAwesomeIcon
                  data-testid="iconButton"
                  id="InstallGIMmanagerIcon"
                  icon={faDownload}
                  role="button"
                  style={{
                    marginRight: 10,
                  }}
                />
                <span>{t('Header.InstallGIMmanager')}</span>{' '}
                {appContext.latestAppVersion}
              </Button>
            ) : null}
            {appContext?.appUpdateAvailable &&
            !hideInstallButton &&
            [
              `${routePaths.proApp}`,
              `${routePaths.storeApp}`,
              `${routePaths.mapsApp}`,
              `${routePaths.transferApp}`,
              `${routePaths.projects}`,
            ].includes(location.pathname) ? (
              <Button
                // className="download-button"
                className="download-exe"
                variant="dark"
                onClick={() => {
                  let url = !!appContext?.latestAppVersion
                    ? `${process.env.REACT_APP_DOMAIN}GIMmanager ${appContext?.latestAppVersion}.exe`
                    : `${process.env.REACT_APP_DOMAIN}GIMmanager.exe`
                  let name = !!appContext?.latestAppVersion
                    ? `GIMmanager ${appContext?.latestAppVersion}.exe`
                    : 'GIMmanager.exe'

                  downloadURI(url, name)
                  dispatch(setHideInstallButton(true))
                  setTimeout(() => {
                    dispatch(setHideInstallButton(false))
                  }, 6 * 60 * 1000)
                  // setTimeout(() => {
                  //   setAppContext({
                  //     ...appContext,
                  //     // appUpdateAvailable: true, //test purpose
                  //     startedToConnectGIMmanager: true,
                  //     connectionCount: 0,
                  //   })
                  // }, 1 * 30 * 1000)
                }}
              >
                <FontAwesomeIcon
                  data-testid="iconButton"
                  id="InstallGIMmanagerIcon"
                  icon={faDownload}
                  role="button"
                  style={{
                    marginRight: 10,
                  }}
                />
                {t('Header.downloadUpdate')} {appContext.latestAppVersion}
              </Button>
            ) : null}
            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              t={t}
              appContext={appContext}
              setAppContext={setAppContext}
            />

            <OverlayTrigger
              trigger="click"
              rootClose
              key="bottom-language"
              placement="bottom"
              overlay={
                <Popover
                  id="popover-positioned-bottom"
                  className="lang-menu"
                  style={{ marginTop: 10 }}
                >
                  <Popover.Content className="header-menu">
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '70px 70px',
                        // gridGap: 5,
                      }}
                    >
                      {renderLanguages()}
                    </div>
                  </Popover.Content>
                </Popover>
              }
            >
              <Nav.Link
                id="language"
                data-testid="languageButton"
                // eventKey="6"
                className="items logo-items globe-button"
              >
                <FontAwesomeIcon
                  data-testid="globeButton"
                  id="globe"
                  icon={faGlobe}
                  role="button"
                  style={{
                    fontSize: 25,
                    color: 'white',
                  }}
                />
              </Nav.Link>
            </OverlayTrigger>
            {/* <NavDropdown
              renderMenuOnMount={true}
              id="basic-nav-dropdown"
              className="text-center user-profile"
              data-testid="navDropdown"
              title={
                <Nav.Link
                  id="language"
                  data-testid="languageButton"
                  // eventKey="6"
                  className="items logo-items globe-button"
                  style={{ padding: 2, margin: '0.5rem 1rem' }}
                >
                  <FontAwesomeIcon
                    data-testid="globeButton"
                    id="globe"
                    icon={faGlobe}
                    role="button"
                    style={{
                      fontSize: 25,
                      color: 'white',
                    }}
                  />
                </Nav.Link>
              }
            >
              <NavDropdown.Item
                id="my-projects"
                as={Link}
                to={`${routePaths.projects}`}
              >
                {renderLanguages()}
              </NavDropdown.Item>
            </NavDropdown> */}
          </>
          {userInfo && Object.keys(userInfo).length > 0 && userid ? (
            <div className="userInfo">
              <NavDropdown
                renderMenuOnMount={true}
                id="basic-nav-dropdown"
                className={`text-center user-profile ${
                  location.pathname === routePaths?.projects ||
                  hoverState === 'MyProject'
                    ? 'user-active-link'
                    : ''
                }`}
                // className="text-center user-profile"
                data-testid="navDropdown"
                // onClick={() =>
                //   history.push({
                //     pathname: `${routePaths.projects}`,
                //   })
                // }
                // as={Link}
                // to={`${routePaths.projects}`}
                title={UserMenu()}
                // title={
                //   userInfo?.localizedFirstName
                //     ? `${userInfo?.localizedFirstName} ${userInfo?.localizedLastName}`
                //     : 'User'
                // }
              >
                <NavDropdown.Item
                  id="my-projects"
                  className={
                    location.pathname === routePaths?.projects ||
                    hoverState === 'MyProject'
                      ? 'user-active-link'
                      : ' '
                  }
                  onMouseEnter={() => onHoverView('MyProject')}
                  onMouseLeave={() => onHoverView('')}
                  as={Link}
                  to={`${routePaths.projects}`}
                  // active={location.pathname === routePaths?.projects}
                >
                  {t('Global.myProjects')}
                </NavDropdown.Item>

                {isAdmin && (
                  <NavDropdown.Item
                    className={
                      location.pathname === routePaths?.invoiceList ||
                      hoverState === 'Invoicelist'
                        ? 'user-active-link'
                        : ''
                    }
                    as={Link}
                    to={`${routePaths.invoiceList}`}
                    // active={location.pathname === routePaths?.invoiceList}
                    onMouseEnter={() => onHoverView('Invoicelist')}
                    onMouseLeave={() => onHoverView('')}
                  >
                    {t('Header.invoiceList')}
                  </NavDropdown.Item>
                )}

                {isAdmin && (
                  <NavDropdown.Item
                    className={
                      location.pathname === routePaths?.projectOverview ||
                      hoverState === 'ProjectOverview'
                        ? 'user-active-link'
                        : ' '
                    }
                    onMouseEnter={() => onHoverView('ProjectOverview')}
                    onMouseLeave={() => onHoverView('')}
                    as={Link}
                    to={`${routePaths.projectOverview}`}
                  >
                    Project Overview
                  </NavDropdown.Item>
                )}

                {isAdmin && (
                  <NavDropdown.Item
                    className={
                      location.pathname === routePaths?.testResult ||
                      hoverState === 'TestResult'
                        ? 'user-active-link'
                        : ' '
                    }
                    onMouseEnter={() => onHoverView('TestResult')}
                    onMouseLeave={() => onHoverView('')}
                    as={Link}
                    to={`${routePaths.testResult}`}
                    // active={location.pathname === routePaths?.testResult}
                  >
                    {t('Header.testResult')}
                  </NavDropdown.Item>
                )}
                <NavDropdown.Divider />

                <Button
                  onClick={() => handleSignout()}
                  className="login-btn btn dropdown-item"
                >
                  {t('Header.signOut')}
                </Button>
              </NavDropdown>
            </div>
          ) : (
            <Nav className="signIn" data-testid="signIn">
              <Signin />
            </Nav>
          )}
        </div>

        <Navbar.Toggle className="toggle" aria-controls="basic-navbar-nav" />
      </Navbar>
    </Headroom>
  )
}
export default Header
