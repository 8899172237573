export const routePaths = {
  root: '/',
  home: '/home',
  // pro: '/pro',
  // store: '/store',
  // maps: '/maps',
  storeInfo: '/store',
  storeTech: '/storetech',
  storeApp: '/storeapp',
  proInfo: '/pro',
  proTech: '/protech',
  proApp: '/proapp',
  mapsInfo: '/maps',
  mapsTech: '/mapstech',
  mapsApp: '/mapsapp',
  transferApp: '/app',
  projectOverview: '/project-overview',
  // solution: '/solutions',
  // customers: '/customers',
  // solutionDemo: '/solutions/demo',
  // solutionIncreaseTheProgress: '/solutions/increase-the-progress',
  // solutionPreFund: '/solutions/pre-fund',
  blog: 'https://blog.gimtransfer.com',
  // downloadDesktop: '/download-desktop-application',
  // linkedIn: '/linkedin-auth',
  // safe: '/safe',
  projects: '/projects',
  // addProject: '/projects/add',
  // editProject: '/projects/:id/edit',
  veryfyProject: '/verify-invoice-address',
  invoiceList: '/invoice-list',
  iFrame: '/iFrame',
  iFrameMap: '/iFrame-map',
  testResult: '/test-result',
  aboutUs: '/about-us',
  contact: '/contact',
  vacancy: '/vacancy',
  privacyPolicy: '/terms-and-conditions',
  faqs: '/faqs',
  werkenBijGim: '/werken-bij-gim',
  internshipMarketing: '/stageplaats-hbo-online-marketing',
  internshipDevelopment: '/stagiaire-hbo-it-ontwikkeling',
}

// export const PROJECT_URL = 'https://api.gimtransfer.com/api/project'
// export const OBJECT_URL = 'https://api.gimtransfer.com/api/object'
export const PLUGIN_URL = 'http://localhost:4300/api'
export const PLUGIN_HOST = 'http://localhost:4300'
export const USER_ID = process.env.REACT_APP_USER_ID
export const EXTERNAL_API = process.env.REACT_APP_EXTERNAL_API
export const EXTENSION_ICON_FILE = `${process.env.REACT_APP_BE_DOMAIN}ExtensionIconFiles`
export const GET_GLB_FILE = `${process.env.REACT_APP_EXTERNAL_API}object/SendGLBFileData`
export const toastDuration = 5000
export const treeNodeLeftPadding = 15
// export const linkedInClientId = '86kufkvxjxd5e6'
export const linkedInClientId = '77kaqgn2qr90g1'
export const FILE_TYPE = 'json'
export const SIGNIN_LINK = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&scope=r_liteprofile%20r_emailaddress&client_id=${linkedInClientId}&redirect_uri=${encodeURIComponent(
  process.env.REACT_APP_REDIRECT_URL
)}`
// export const DOWNLOAD_FILE_PATH =
//   'https://glb.gimtransfer.online/UploadConvertedFiles/Test/'
export const DOWNLOAD_FILE_PATH = process.env.REACT_APP_DIRECT_DOWNLOAD_URL
export const WORDPRESS_BASE_URL = 'https://main.gimtransfer.com/'

export const GimUnitsData = [
  'm_0_125 nm',
  'm_0_25 nm',
  'm_0_5 nm',
  'm_1 nm',
  'm_1_25 nm',
  'm_2_5 nm',
  'm_5 nm',
  'm_10 nm',
  'm_12_5 nm',
  'm_25 nm',
  'm_50 nm',
  'm_100 nm',
  'm_0_125 \u00B5m',
  'm_0_25 \u00B5m',
  'm_0_5 \u00B5m',
  'm_1 \u00B5m',
  'm_1_25 \u00B5m',
  'm_2_5 \u00B5m',
  'm_5 \u00B5m',
  'm_10 \u00B5m',
  'm_12_5 \u00B5m',
  'm_25 \u00B5m',
  'm_50 \u00B5m',
  'm_100 \u00B5m',
  'm_0_125 mm',
  'm_0_25 mm',
  'm_0_5 mm',
  'm_1 mm',
  'm_1_25 mm',
  'm_2_5 mm',
  'm_5 mm',
  'm_10 mm',
  'm_12_5 mm',
  'm_25 mm',
  'm_50 mm',
  'm_100 mm',
  'm_0_125 m',
  'm_0_25 m',
  'm_0_5 m',
  'm_1 m',
  'm_1_25 m',
  'm_2_5 m',
  'm_5 m',
  'm_10 m',
  'm_12_5 m',
  'm_25 m',
  'm_50 m',
  'm_100 m',
  'm_0_125 km',
  'm_0_25 km',
  'm_0_5 km',
  'm_1 km',
  'm_1_25 km',
  'm_2_5 km',
  'm_5 km',
  'm_10 km',
  'm_12_5 km',
  'm_25 km',
  'm_50 km',
  'm_100 km',
  'm_0_125 Mm',
  'm_0_25 Mm',
  'm_0_5 Mm',
  'm_1 Mm',
  'm_1_25 Mm',
  'm_2_5 Mm',
  'm_5 Mm',
  'm_10 Mm',
  'm_12_5 Mm',
  'm_25 Mm',
  'm_50 Mm',
  'm_100 Mm',
  'Id_512 inch',
  'Id_256 inch',
  'Id_128 inch',
  'Id_64 inch',
  'Id_32 inch',
  'Id_16 inch',
  'Id_8 inch',
  'Id_4 inch',
  'Id_2 inch',
  'I_1 inch',
  'I_1_25 inch',
  'I_2_5 inch',
  'I_5 inch',
  'I_10 inch',
  'ft_1 feet',
  'ft_1_25 feet',
  'ft_2_5 feet',
  'ft_5 feet',
  'ft_10 feet',
  'ft_12_5 feet',
  'ft_25 feet',
  'ft_50 feet',
  'ft_100 feet',
  'ft_125 feet',
  'ft_250 feet',
  'ft_500 feet',
  'ft_1000 feet',
  'ft_1250 feet',
  'ft_2500 feet',
  'ft_5000 feet',
  'ft_10000 feet',
  'ft_12500 feet',
  'ft_25000 feet',
  'ft_50000 feet',
  'ft_100000 feet',
  'ml_1 mile',
  'ml_1_25 mile',
  'ml_2_5 mile',
  'ml_5 mile',
  'ml_10 mile',
  'ml_12_5 mile',
  'ml_25 mile',
  'ml_50 mile',
  'ml_100 mile',
  'ml_125 mile',
  'ml_250 mile',
  'ml_500 mile',
  'ml_1000 mile',
  'ml_1250 mile',
  'ml_2500 mile',
  'ml_5000 mile',
  'ml_10000 mile',
  'ml_12500 mile',
  'ml_25000 mile',
  'ml_50000 mile',
  'ml_100000 mile',
]

export const extensionList = [
  '3DM',
  'BIN',
  'BREP',
  'DAE',
  'DXF',
  'FBX',
  'GLB',
  'GLTF',
  'IFC',
  'IGES',
  'IGS',
  'JSON',
  'JT',
  'OBJ',
  'SAB',
  'SAT',
  'STEP',
  'STL',
  'STP',
  'WRL',
  'X_B',
  'X_T',
  'X3D',
  'XMB_BIN',
  'XMB_TXT',
  'XMT_BIN',
  'XMT_TXT',
]

export const dummyProj = [
  {
    collapsed: true,
    name: 'Object',
    projectId: 137,
    type: 'project',
    projectMemberAccessRights: 'A',
    viewStatus: 'D',
  },
]
export const CustomizeInitData = {
  sideBar: {
    backgroundColor: '#1a1a1a',
    font: {
      fontFamily: 'BlinkMacSystemFont',
      color: '#c5c5c5',
      fontWeight: '',
      fontStyle: 'italic',
    },
    icon: {
      color: '#756e6e',
      activeColor: 'rgb(230, 174, 34)',
      opacity: 1,
    },
    searchInput: {
      backgroundColor: '#111',
      borderRadius: '12px',
      font: {
        fontFamily: 'unset',
        color: '#fff',
        fontWeight: 'unset',
        fontStyle: 'unset',
      },
    },
    projectTitle: {
      backgroundColor: '#2d2d2d',
      font: {
        fontFamily: 'unset',
        color: '#c5c5c5',
        fontWeight: 'unset',
        fontStyle: 'unset',
      },
    },
    objectList: {
      backgroundColor: '#353535',
      font: {
        fontFamily: 'unset',
        color: '#c5c5c5',
        fontWeight: 'unset',
        fontStyle: 'unset',
      },
    },
  },
  canvas: {
    backgroundColor: '#1a1a1a',
    font: {
      fontFamily: 'unset',
      color: '#fff',
      fontWeight: 'unset',
      fontStyle: 'unset',
    },
    icon: {
      color: '#fff',
      opacity: 1,
    },
  },
}
