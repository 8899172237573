import React, { useContext } from 'react'

import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { AppContext } from '../../App'
import { setMobileView } from '../../redux/actions/user/user.actions'
import { routePaths } from '../../shared/config/globalConfig'
// import Footer from '../Footer/Footer'
import Loader from '../Loader'
import Header from '../Navigation/Header'

import './layout.scss'

const Layout = (props) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { appContext } = useContext(AppContext)

  const handleWindowSizeChange = () => {
    dispatch(setMobileView(window.innerWidth))
  }

  React.useEffect(() => {
    handleWindowSizeChange()
    // window.addEventListener('resize', handleWindowSizeChange)
    // return () => {
    //   window.removeEventListener('resize', handleWindowSizeChange)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkPages = () => {
    let classAdd = [
      `${routePaths.faqs}`,
      `${routePaths.aboutUs}`,
      `${routePaths.root}`,
      `${routePaths.home}`,
      `${routePaths.vacancy}`,
      `${routePaths.werkenBijGim}`,
      `${routePaths.internshipMarketing}`,
      `${routePaths.internshipDevelopment}`,
      `${routePaths.contact}`,
      `${routePaths.privacyPolicy}`,
      `${routePaths.storeInfo}`,
      `${routePaths.storeTech}`,
      `${routePaths.proInfo}`,
      `${routePaths.proTech}`,
      `${routePaths.mapsInfo}`,
      `${routePaths.mapsTech}`,
    ].includes(location.pathname)
      ? true
      : false
    if (classAdd) {
      return 'iframe-page'
    } else {
      return ''
    }
  }

  return (
    <div
      className={`${
        location?.pathname?.includes(routePaths?.projects)
          ? 'project-container'
          : `app ${
              location.pathname?.includes(routePaths.proApp) ||
              location.pathname?.includes(routePaths.transferApp) ||
              location.pathname?.includes(routePaths.storeApp) ||
              location.pathname?.includes(routePaths.mapsApp)
                ? 'gim pro'
                : checkPages()
            } `
      }`}
      // className={`${
      //   location.pathname?.includes(routePaths.proInfo) ||
      //   location.pathname?.includes(routePaths.storeInfo) ||
      //   location.pathname?.includes(routePaths.mapsInfo)
      //     ? location?.pathname?.includes(routePaths?.projects)
      //       ? 'project-container'
      //       : 'app gim pro'
      //     : 'app'
      // }`}
      // className={`app ${
      //   location.pathname?.includes(routePaths.pro) ||
      //   location.pathname?.includes(routePaths.store)
      //     ? location?.pathname?.includes(routePaths?.projects)
      //       ? ''
      //       : 'gim pro'
      //     : ''
      // }`}
    >
      <Loader isLoading={appContext.showAppLoader} />
      <ToastContainer />
      <Header />
      {props.children}
      {/* {[
        `${routePaths.proApp}`,
        `${routePaths.storeApp}`,
        `${routePaths.mapsApp}`,
        `${routePaths.projects}`,
        `${routePaths.testResult}`,
      ].includes(location.pathname) ? null : (
        <Footer />
      )} */}
    </div>
  )
}

export default Layout
