export const LOD_12 = "12";
export const LOD_22 = "22";

export const getLODSuffix = (selectedGLBVersion) => {
  switch(selectedGLBVersion){
    case LOD_12: return "_22";
    case LOD_22: return "_12";
  }
  return "_12";
}

export const formatLODString = (lodConst) =>{
  switch(lodConst){
    case LOD_12: return "1.2";
    case LOD_22: return "2.2";
  }
  return "1.2";
}

export const handleError = (error, dispatch) => {
  let errorMessage = 'Something went wrong'

  console.log('error', error, error.response)

  if (error.response && error.response.message) {
    errorMessage = error.response.message
  }

  return errorMessage
}

/**
 * Function Use
 * console.log(findNestedObj(input, 'id', '1'));
 */
export function findNestedObj(entireObj, keyToFind, valToFind) {
  let foundObj
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue
    }
    return nestedValue
  })
  return foundObj
}

export const logger = (...args) => {
  if (process.env.NODE_ENV !== 'production') {
    //console.trace();
    console.trace(...args);
  }
}


export const errorLogger = (...args) => {
  console.log(...args)
}

export const downloadURI = (uri, name, type) => {
  let contentType = 'application/json;charset=utf-8;'
  const link = document.createElement('a')
  link.download = name
  // 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData));
  link.href =
    type === 'json'
      ? `data:${contentType},${encodeURIComponent(JSON.stringify(uri))}`
      : uri
  document.body.appendChild(link)
  // link.target = '_blank'
  link.click()
  document.body.removeChild(link)

  // // Create a blob with the data we want to download as a file
  // const blob = new Blob([JSON.stringify(uri)], { type: 'text/json' })
  // // Create an anchor element and dispatch a click event on it
  // // to trigger a download
  // const a = document.createElement('a')
  // a.download = name
  // a.href = window.URL.createObjectURL(blob)
  // const clickEvt = new MouseEvent('click', {
  //   view: window,
  //   bubbles: true,
  //   cancelable: true,
  // })
  // a.dispatchEvent(clickEvt)
  // a.remove()
}

export const isValidEmail = (email) => {
  // const re =
  //   /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  const re = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z0-9]{2,4})/gm
  return re.test(email)
}

export const getAccessRight = (rights) => {
  let label = ''
  switch (rights) {
    case 'V':
      label = 'View Only'
      break
    case 'U':
      label = 'View and download'
      break
    case 'E':
      label = 'Full - Edit, View and Download'
      break
    case 'A':
      label = 'Admin'
      break

    default:
      break
  }
  return label
}

export const isValidDomain = (domain) => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(?:!https?:|s?!ftps?:)?((?!www\.))(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/gm
  if (domain.includes('http://') || domain.includes('https://')) {
    return false
  }
  return re.test(domain)
}
export const isValidIntNumber = (num) => {
  return Number.isInteger(num) && num <= 2147483647
}

export const copyToClipboard = (text) => {
  var textField = document.createElement('textarea')
  textField.innerText = text
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
}

export const isValidDiscount = (num) => {
  const reg = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/gm
  if (num === '') {
    return true
  }
  return reg.test(num)
}

export const convertRadianToDegree = (value) => {}

export const convertDegreeToRadian = (value) => {}

export const checkNan = (data) => {
  if (isNaN(data)) {
    return 0
  }
  return Number(data)
}

export const getUnitLongName = (unit) => {
  switch (unit) {
    case 'Mm':
      return 'megametre'
    case 'mm':
      return 'millimetre'
    case 'nm':
      return 'nanometer'
    case 'µm':
      return 'micrometer'
    case 'inch':
      return 'inch'
    case 'm':
      return 'meter'
    case 'ft':
      return 'feet'
    case 'km':
      return 'kilometer'
    case 'mile':
      return 'mile'
    default:
      return null
  }
}
