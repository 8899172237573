import { createSelector } from 'reselect'

const user = (state) => state.user

export const projectListSelector = createSelector(user, (item) => item.projects)

export const gimProjectListSelector = createSelector(
  user,
  (item) => item.gimProjects
)

export const fileListSelector = createSelector(user, (item) => item.fileList)

export const fileUrlSelector = createSelector(user, (item) => item.gltfFileUrl)

export const fileTypeSelector = createSelector(user, (item) => item.fileTypes)

export const userInfoSelector = createSelector(user, (item) => item.userInfo)
export const userIdSelector = createSelector(user, (item) => item.userId)

export const initProjectListSelector = createSelector(
  user,
  (item) => item.initialtProjectList
)
export const selectprojectwithIdSelector = createSelector(
  user,
  (item) => item.selectprojectwithId
)
