import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// Importing translation files
import German from './locale/de/de.json'
import English from './locale/en/en.json'
import French from './locale/fr/fr.json'
import Italian from './locale/it/it.json'
import Dutch from './locale/nl/nl.json'

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: English,
  },
  nl: {
    translation: Dutch,
  },
  de: {
    translation: German,
  },
  it: {
    translation: Italian,
  },
  fr: {
    translation: French,
  },
}

//i18N Initialization

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: {
      // order: ['path', 'querystring', 'navigator', 'htmlTag', 'subdomain'],
      order: ['localStorage', 'navigator'],
    },
    fallbackLng: {
      'en-US': ['en'],
    },
    // lng: 'en', //default language/ Comment this line to work language detector
    // keySeparator: false, //remove line to work with nested object keys
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ['en', 'nl', 'de', 'it', 'fr'],
    whitelist: ['en', 'nl', 'de', 'it', 'fr'],
  })
// eslint-disable-next-line import/no-named-as-default-member
i18n.changeLanguage()

export default i18n
