import { combineReducers } from 'redux'

import authReducer from './auth.reducer'
import userReducer from './user.reducer'

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
