import {
  ADD_OBJECT_TO_INPROGRESS,
  ALLOWED_EXTENSIONS,
  CHANGE_LANGUAGE,
  C_INPUT_LIST,
  FETCH_FILE_REQUEST,
  FETCH_FILE_RESULT,
  FILE_LIST,
  FILE_TYPES,
  FILE_URL,
  GIM_PROJECTS,
  GLB_LOAD_STATE,
  INDUSTRY_DATA,
  INIT_PROJECT_LIST,
  INVOICE_LIST,
  LOGGED_IN,
  LOG_OUT,
  PROJECT_LIST,
  REMOVE_OBJECT_FROM_INPROGRESS,
  SET_CUSTOMIZE_DATA,
  SET_DOWNLOAD_PROGRESS,
  SET_GIM_AUTH_TOKEN,
  SET_HIDE_INSTALL_BUTTON,
  SET_IFRAME_STATE,
  SET_MOBILE_VIEW,
  SET_UPLOAD_PROGRESS,
  SET_ZOOM_IN_LIMIT,
  USER_INFO,
} from '../types/user'

const initialState = {
  userInfoData: {},
  userInfo: {},
  userId: '',
  authToken: '',
  gimAuthToken: '',
  industryDummyData: [],
  invoiceListDummyData: [],
  projects: [], // projects we receive from server API
  fileList: [], // list of files from project's directory
  gimProjects: { downloadList: [], projectList: [], uploadList: [] }, // projects which are listed on gim JSON file
  gltfFileUrl: '',
  industryTestData: [],
  isFileFetching: false,
  fileTypes: [
    'BREP',
    'DXF',
    'FBX',
    'GLTF',
    'IFC',
    'IGES',
    'JT',
    'OBJ',
    'STL',
    'X3D',
  ],
  allowedExtensions: [],
  initialtProjectList: [],
  selectprojectwithId: [],
  objectsInProgress: [],
  userLanguage: localStorage.getItem('i18nextLng') ?? 'en',
  objectViewerType: 'group',
  mobileViewWidth: 990,
  isMobileView: false,
  defaultFileTypes: [
    {
      fileType: 'BIN',
      iconUrl: `${process.env.REACT_APP_BE_DOMAIN}ExtensionIconFiles/GIMZIP.png`,
    },
    {
      fileType: 'JSON',
      iconUrl: `${process.env.REACT_APP_BE_DOMAIN}ExtensionIconFiles/JSON.png`,
    },
  ],
  uploadProgress: { guid: null, progressStatus: 0 },
  downloadProgress: [],
  GimUnitsData: [
    'm_0_125 nm',
    'm_0_25 nm',
    'm_0_5 nm',
    'm_1 nm',
    'm_1_25 nm',
    'm_2_5 nm',
    'm_5 nm',
    'm_10 nm',
    'm_12_5 nm',
    'm_25 nm',
    'm_50 nm',
    'm_100 nm',
    'm_0_125 \u00B5m',
    'm_0_25 \u00B5m',
    'm_0_5 \u00B5m',
    'm_1 \u00B5m',
    'm_1_25 \u00B5m',
    'm_2_5 \u00B5m',
    'm_5 \u00B5m',
    'm_10 \u00B5m',
    'm_12_5 \u00B5m',
    'm_25 \u00B5m',
    'm_50 \u00B5m',
    'm_100 \u00B5m',
    'm_0_125 mm',
    'm_0_25 mm',
    'm_0_5 mm',
    'm_1 mm',
    'm_1_25 mm',
    'm_2_5 mm',
    'm_5 mm',
    'm_10 mm',
    'm_12_5 mm',
    'm_25 mm',
    'm_50 mm',
    'm_100 mm',
    'm_0_125 m',
    'm_0_25 m',
    'm_0_5 m',
    'm_1 m',
    'm_1_25 m',
    'm_2_5 m',
    'm_5 m',
    'm_10 m',
    'm_12_5 m',
    'm_25 m',
    'm_50 m',
    'm_100 m',
    'm_0_125 km',
    'm_0_25 km',
    'm_0_5 km',
    'm_1 km',
    'm_1_25 km',
    'm_2_5 km',
    'm_5 km',
    'm_10 km',
    'm_12_5 km',
    'm_25 km',
    'm_50 km',
    'm_100 km',
    'm_0_125 Mm',
    'm_0_25 Mm',
    'm_0_5 Mm',
    'm_1 Mm',
    'm_1_25 Mm',
    'm_2_5 Mm',
    'm_5 Mm',
    'm_10 Mm',
    'm_12_5 Mm',
    'm_25 Mm',
    'm_50 Mm',
    'm_100 Mm',
    'Id_512 inch',
    'Id_256 inch',
    'Id_128 inch',
    'Id_64 inch',
    'Id_32 inch',
    'Id_16 inch',
    'Id_8 inch',
    'Id_4 inch',
    'Id_2 inch',
    'I_1 inch',
    'I_1_25 inch',
    'I_2_5 inch',
    'I_5 inch',
    'I_10 inch',
    'ft_1 feet',
    'ft_1_25 feet',
    'ft_2_5 feet',
    'ft_5 feet',
    'ft_10 feet',
    'ft_12_5 feet',
    'ft_25 feet',
    'ft_50 feet',
    'ft_100 feet',
    'ft_125 feet',
    'ft_250 feet',
    'ft_500 feet',
    'ft_1000 feet',
    'ft_1250 feet',
    'ft_2500 feet',
    'ft_5000 feet',
    'ft_10000 feet',
    'ft_12500 feet',
    'ft_25000 feet',
    'ft_50000 feet',
    'ft_100000 feet',
    'ml_1 mile',
    'ml_1_25 mile',
    'ml_2_5 mile',
    'ml_5 mile',
    'ml_10 mile',
    'ml_12_5 mile',
    'ml_25 mile',
    'ml_50 mile',
    'ml_100 mile',
    'ml_125 mile',
    'ml_250 mile',
    'ml_500 mile',
    'ml_1000 mile',
    'ml_1250 mile',
    'ml_2500 mile',
    'ml_5000 mile',
    'ml_10000 mile',
    'ml_12500 mile',
    'ml_25000 mile',
    'ml_50000 mile',
    'ml_100000 mile',
  ],
  hideInstallButton: false,
  isIframe: false,
  customizeData: {
    sideBar: {
      backgroundColor: '#1a1a1a',
      font: {
        fontFamily: 'BlinkMacSystemFont',
        color: '#c5c5c5',
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
      icon: {
        color: '#c5c5c5',
        activeColor: '#E6AC22',
        inActiveColor: '#756e6e',
        opacity: 1,
      },
      searchInput: {
        backgroundColor: '#111',
        borderRadius: '12px',
        font: {
          fontFamily: 'BlinkMacSystemFont',
          color: '#fff',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
      },
      projectTitle: {
        backgroundColor: '#2d2d2d',
        font: {
          fontFamily: 'BlinkMacSystemFont',
          color: '#c5c5c5',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
      },
      objectList: {
        backgroundColor: '#353535',
        font: {
          fontFamily: 'BlinkMacSystemFont',
          color: '#c5c5c5',
          fontWeight: 'normal',
          fontStyle: 'normal',
        },
      },
    },
    canvas: {
      backgroundColor: '#1a1a1a',
      font: {
        fontFamily: 'BlinkMacSystemFont',
        color: '#fff',
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
      icon: {
        color: '#fff',
        activeColor: 'goldenrod',
        buttonBackgroundColor: 'goldenrod',
        opacity: 1,
      },
    },
  },
  isAllGLBLoaded: false,
  zoomInLimit: 2,
}

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ZOOM_IN_LIMIT:
      return {
        ...state,
        zoomInLimit: payload,
      }
    case GLB_LOAD_STATE:
      return {
        ...state,
        isAllGLBLoaded: payload,
      }
    case SET_IFRAME_STATE:
      return {
        ...state,
        isIframe: payload,
      }
    case SET_CUSTOMIZE_DATA:
      return {
        ...state,
        customizeData: payload,
      }

    case SET_HIDE_INSTALL_BUTTON:
      return {
        ...state,
        hideInstallButton: payload,
      }

    case SET_DOWNLOAD_PROGRESS:
      return {
        ...state,
        downloadProgress: payload,
      }

    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: payload,
      }

    case SET_MOBILE_VIEW:
      return {
        ...state,
        isMobileView: payload < 990,
      }
    case CHANGE_LANGUAGE:
      return {
        ...state,
        userLanguage: payload,
      }
    case ADD_OBJECT_TO_INPROGRESS:
      return {
        ...state,
        objectsInProgress: payload,
      }
    case REMOVE_OBJECT_FROM_INPROGRESS:
      return {
        ...state,
        objectsInProgress: payload,
      }
    case SET_GIM_AUTH_TOKEN:
      return {
        ...state,
        gimAuthToken: payload,
      }
    case LOGGED_IN:
      return {
        ...state,
        userInfo: payload?.UserDetails,
        authToken: payload?.token,
        userId: payload?.UserId,
      }
    case PROJECT_LIST:
      return {
        ...state,
        isFileFetching: false,
        projects: payload,
      }
    case GIM_PROJECTS:
      return {
        ...state,
        gimProjects: payload,
      }
    case FILE_LIST:
      return {
        ...state,
        fileList: payload,
      }
    case FILE_URL:
      return {
        ...state,
        gltfFileUrl: payload,
      }
    case FILE_TYPES:
      return {
        ...state,
        ...state?.defaultFileTypes,
        fileTypes: payload,
      }
    case ALLOWED_EXTENSIONS:
      return {
        ...state,
        allowedExtensions: payload,
      }
    case FETCH_FILE_REQUEST:
      return {
        ...state,
        isFileFetching: true,
      }
    case FETCH_FILE_RESULT:
      return {
        ...state,
        isFileFetching: false,
      }
    case LOG_OUT:
      return {
        userInfo: {},
        authToken: '',
        userId: '',
        projects: [],
      }
    case INIT_PROJECT_LIST:
      return {
        ...state,
        initialtProjectList: payload,
      }
    case C_INPUT_LIST:
      return {
        ...state,
        selectprojectwithId: payload,
      }
    case INDUSTRY_DATA:
      return {
        ...state,
        industryDummyData: payload,
      }
    case INVOICE_LIST:
      return {
        ...state,
        invoiceListDummyData: payload,
      }
    case USER_INFO:
      return {
        ...state,
        userInfo: payload,
        userId: payload?.userId,
      }

    default:
      return state
  }
}

export default userReducer
