import React, { useCallback } from 'react'

import { Button, Form, Image, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import original from '../../assets/original.png'
import { setHideInstallButton } from '../../redux/actions/user/user.actions'
import { downloadURI } from '../../shared/config/globalFunctions'

const InstallGimManagerModal = ({ t, appContext, setAppContext, ...props }) => {
  const dispatch = useDispatch()

  const getDownloadDetails = useCallback(() => {
    const version = appContext?.latestAppVersion
    const fileName = version ? `GIMmanager ${version}.exe` : 'GIMmanager.exe'
    const url = `${process.env.REACT_APP_DOMAIN}${fileName}`
    return { url, fileName }
  }, [appContext])

  const handleClick = useCallback(() => {
    const { url, fileName } = getDownloadDetails()
    downloadURI(url, fileName)

    // hide model and hide button from the header.
    setTimeout(() => {
      dispatch(setHideInstallButton(true))
      props.onHide()
    }, 1500)

    // Start interval to check GIMmanager is connected or not after 3 minutes.
    setTimeout(() => {
      setAppContext({
        ...appContext,
        startedToConnectGIMmanager: true,
        connectionCount: 0,
      })
    }, 3 * 60 * 1000)

    //Show button on the header after 6 minutes after click on install button.
    setTimeout(() => {
      dispatch(setHideInstallButton(false))
    }, 6 * 60 * 1000)
  }, [appContext, dispatch, getDownloadDetails, props, setAppContext])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="managerModal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="title" id="contained-modal-title-vcenter">
          {t('Header.installGimmanager')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row w-full align-items-center">
          <div
            className="col-md-10 order-2 order-md-1"
            style={{ paddingRight: 0 }}
          >
            <p style={{ marginTop: '1rem' }}>
              {t('Header.GIMmanagerPopupSection1')}
            </p>
            <p>
              {t('Header.GIMmanagerPopupSection2')}{' '}
              <Link to="/faqs" target="_blank">
                {t('Header.needToKnowMoreOnThis')}
              </Link>
            </p>
          </div>
          <div className="col-md-2 order-md-2 order-1" style={{ padding: 0 }}>
            <Image
              className="d-block mx-auto img-fluid logo-size"
              src={original}
            />
          </div>
        </div>
      </Modal.Body>
      <Form>
        <Button
          className="download-button"
          variant="dark"
          onClick={() => handleClick()}
        >
          {t('Header.installForWindows')}
        </Button>
      </Form>
    </Modal>
  )
}

export default InstallGimManagerModal
