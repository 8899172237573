import React, { createContext, lazy, useRef, useState } from 'react'

import { Steps } from 'intro.js-react'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { toast } from 'react-toastify'

import Intro_Settings from './assets/PixelArt/10_Settings.png'
import Intro_Model from './assets/PixelArt/11_Model.png'
import Intro_Edit from './assets/PixelArt/12_Edit.png'
import Intro_Delete from './assets/PixelArt/13_Delete.png'
import Intro_Download from './assets/PixelArt/14_Download.png'
import Intro_HiThere from './assets/PixelArt/1_HiThere.png'
import Intro_GIMPRO from './assets/PixelArt/2_GIMPRO.png'
import Intro_GIMSTORE from './assets/PixelArt/3_GIMSTORE.png'
import Intro_PRJ_List from './assets/PixelArt/4_PRJ_List.png'
import Intro_PRJ_Link from './assets/PixelArt/5_PRJ_Link.png'
import Intro_OBJ_List from './assets/PixelArt/6_OBJ_List.png'
import Intro_OBJ_Link from './assets/PixelArt/7_OBJ_Link.png'
import Intro_ThreeDCanvas from './assets/PixelArt/8_3DCanvas.png'
import Intro_GIMmanager from './assets/PixelArt/9_GIMmanager.png'
import Intro_MyPrj_ from './assets/PixelArt/MyPrj.png'
import Layout from './components/Layout'
import { checkMandatoryVersion } from './redux/actions/desktopApp/desktopApp.actions'
import { userInfoSelector } from './redux/selectors/user.selector'
import { routePaths } from './shared/config/globalConfig'
import 'react-toastify/dist/ReactToastify.css'

import './styles/style.scss'
import './i18n'
import 'intro.js/introjs.css'

const Home = lazy(() => import('./pages/Home'))
const AboutUs = lazy(() => import('./pages/Home/Pages/AboutUs'))
const Contact = lazy(() => import('./pages/Home/Pages/Contact'))
const Vacancy = lazy(() => import('./pages/Home/Pages/Vacancy'))
const WerkenBijGIM = lazy(() => import('./pages/Home/Pages/WerkenBijGim'))
const InternshipMarketing = lazy(() =>
  import('./pages/Home/Pages/InternshipMarketing')
)
const InternshipDevelopment = lazy(() =>
  import('./pages/Home/Pages/InternshipDevelopment')
)
const PrivacyPolicy = lazy(() => import('./pages/Home/Pages/PrivacyPolicy'))
const FAQs = lazy(() => import('./pages/Home/Pages/FAQs'))
const Gim = lazy(() => import('./pages/Gim/gim'))
const GimMaps = lazy(() => import('./pages/Gim/gimMap'))
const StoreInfo = lazy(() => import('./pages/Gim/pages/StoreInfo'))
const StoreTech = lazy(() => import('./pages/Gim/pages/StoreTech'))
const ProInfo = lazy(() => import('./pages/Gim/pages/ProInfo'))
const ProTech = lazy(() => import('./pages/Gim/pages/ProTech'))
const MapsInfo = lazy(() => import('./pages/Gim/pages/MapsInfo'))
const MapsTech = lazy(() => import('./pages/Gim/pages/MapsTech'))
// const Solution = lazy(() => import('./pages/Solution'))
// const Demo = lazy(() => import('./pages/Solution/Demo'))
// const PreFund = lazy(() => import('./pages/Solution/PreFund'))
// const Vote = lazy(() => import('./pages/Solution/Vote'))
// const Customer = lazy(() => import('./pages/Customers'))
const NotFound = lazy(() => import('./components/NotFound'))
const AccessDenied = lazy(() => import('./components/AccessDenied'))
// const DownloadExe = lazy(() => import('./components/DownloadExe'))
// const Trail = lazy(() => import('./pages/Trail'))
const Verify = lazy(() => import('./pages/Verify'))
const Iframe = lazy(() => import('./pages/Iframe'))
const IframeMap = lazy(() => import('./pages/IframeMap'))
const InvoiceList = lazy(() => import('./pages/InvoiceList'))
const ProjectOverview = lazy(() => import('./pages/ProjectOverview'))
// const LinkedIn = lazy(() => import('./pages/Gim/LinkedInAuth'))
// const Safe = lazy(() => import('./pages/Safe/safe'))
const Projects = lazy(() => import('./pages/Projects/projectList'))
const TestResult = lazy(() => import('./pages/TestResult'))

export const AppContext = createContext({})

// The `ThemedRoute` component conditionally renders either a regular `Route` with the provided `Component`
// or a `Route` wrapped in a `Layout` component, depending on whether the path includes specific patterns related to trails, project verification, or iframes.
export const ThemedRoute = ({ component: Component, ...rest }) => {
  if (
    rest?.path?.includes('/trail') ||
    rest?.path?.includes(routePaths.veryfyProject) ||
    rest?.path?.includes(routePaths.iFrame)
  ) {
    return <Route {...rest} render={(props) => <Component {...props} />} />
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  }
}

// The `AuthAdminRoute` component renders either the provided `Component` within a `Layout` if the user is authenticated and is an admin,
// or an `AccessDenied` component within a `Layout` if the user is authenticated but not an admin; otherwise, it redirects to the home page.
export const AuthAdminRoute = ({
  component: Component,
  isAuthenticated,
  isAdmin,
  userRole,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          isAdmin ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Layout>
              <AccessDenied {...props} />
            </Layout>
          )
        ) : (
          <Redirect to={{ pathname: routePaths.home }} />
        )
      }
    />
  )
}

// The `AuthRoute` component renders the provided `Component` within a `Layout` if the user is authenticated, and redirects to the home page if the user is not authenticated.
export const AuthRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: routePaths.home }} />
        )
      }
    />
  )
}

// The `App` component initializes various state variables and retrieves user information and authentication details using Redux selectors,
// also utilizing hooks for translation and checking mobile view status, while managing app-related state through a `useState` hook with an initial context.
const App = (props) => {
  const userInfo = useSelector(userInfoSelector)
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const isAuthenticated = Object.keys(userInfo).length > 0
  const { isAdmin, userRole, isGimConnected } = useSelector(
    (state) => state?.auth
  )
  const { isMobileView } = useSelector((state) => state?.user)

  const StepsRef = useRef()
  const [appContext, setAppContext] = useState({
    latestAppVersion: '',
    appUpdateAvailable: false,
    showAppLoader: false,
    showInstallerButton: false,
    showIntro: false,
    startedToConnectGIMmanager: false,
    connectionCount: 0,
    isUpdateMandatory: false,
  })

  const [initialStep] = useState(0)
  const [steps, setSteps] = useState([])

  React.useEffect(() => {
    setTimeout(() => {
      if (isGimConnected && appContext?.appUpdateAvailable) {
        dispatch(checkMandatoryVersion())
          .then((res) => {
            setAppContext({
              ...appContext,
              isUpdateMandatory: !!res?.data?.isUpdateMandatory,
            })
            if (res?.data?.isUpdateMandatory) {
              toast.info(t('Gim.mandatoryUpdate'), {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: false,
              })
            }
          })
          .catch((error) => console.log('error:', error))
      }
    }, 1500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGimConnected, appContext.appUpdateAvailable, dispatch, t])

  React.useEffect(() => {
    let allSteps = [
      {
        title: t('Intro.tourGuide'),
        intro: `<div style="display: flex;">
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-right: 8px;">
        ${t('Intro.tourGuideIntro')}
        </div>
        <div style="width: 30%; display: flex; justify-content: center;">
        <img src=${Intro_HiThere} />
        </div>
        </div>`,
        highlightClass: 'appTour',
      },
      {
        title: 'GIMpro',
        element: '#GimPro',
        intro: `<div style="display: flex;">
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-right: 8px;">
        ${t('Intro.gimProIntro')}
        </div>
        <div style="width: 30%; display: flex; justify-content: center;">
        <img src=${Intro_GIMPRO}  />
        </div>
        </div>`,
        highlightClass: 'appTour',
      },
      {
        title: 'GIMstore',
        element: '#GimStore',
        intro: `<div style="display: flex;">
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-right: 8px;">
        ${t('Intro.gimStoreIntro')}
        </div>
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_GIMSTORE}  />
        </div>
        </div>`,
      },
      {
        title: t('Intro.myProjects'),
        element: '#my-projects',
        intro: `<div style="display: flex;">
        <div style="width: 30%; display: flex; justify-content: center;">
        <img src=${Intro_MyPrj_} />
        </div>
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-left: 8px;">
        ${t('Intro.myProjectsIntro')}
        </div>
        </div>`,
        highlightClass: 'appTour',
      },
      {
        title: t('Intro.projectList'),
        element: '#ProjectList',
        intro: `<div style="display: flex;">
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-right: 8px;">
        ${t('Intro.projectListIntro')}
        </div>
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_PRJ_List} />
        </div>
        </div>`,
        position: 'right',
        // highlightClass: 'myHighlightClass',
      },
      // {
      //   title: 'Project List',
      //   element: '#ProjectList',
      //   intro: 'Please click on project.',
      //   position: 'right',
      //   hint: 'click on project',
      // },
      {
        title: t('Intro.projectLink'),
        element: '#projectExternalLink',
        // '#ProjectList .d-flex.align-items-center.px-3:nth-child(1) div:nth-child(2)',
        intro: `<div style="display: flex;">
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-right: 8px;">
        ${t('Intro.projectLinkIntro')}
        </div>
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_PRJ_Link}  />
        </div>
        </div>`,
        position: 'right',
      },
      {
        title: t('Intro.objectList'),
        element: '.tree-view-container',
        intro: `<div style="display: flex;">
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_OBJ_List}  />
        </div>
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-left: 8px;">
        ${t('Intro.objectListIntro')}
        </div>
        </div>`,
        position: 'right',
      },
      {
        title: t('Intro.objectLink'),
        element:
          '.tree-view-container .tree-view-children div:nth-child(1)  div:nth-child(3)',
        intro: `<div style="display: flex;">
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_OBJ_Link}  />
        </div>
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-left: 8px;">
        ${t('Intro.objectLinkIntro')}
        </div>
        </div>`,
        position: 'right',
      },
      {
        title: t('Intro.canvas'),
        element: '.viewer-container',
        intro: `<div style="display: flex;">
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-right: 8px;">
        ${t('Intro.canvasIntro')}
        </div>
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_ThreeDCanvas}  />
        </div>
        </div>`,
        tooltipClass: 'customTooltip',
        position: 'left',
      },
      {
        title: 'GIMmanager',
        element: '#InstallGIMmanager',
        intro: `<div style="display: flex;">
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-left: 8px;">
        ${t('Intro.gimManagerIntro')}
        </div>
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_GIMmanager}  />
        </div>
        </div>`,
        // position: 'left',
      },
      {
        title: t('Global.settings'),
        element: '#Settings',
        intro: `<div style="display: flex;">
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_Settings}  />
        </div>
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-left: 8px;">
        ${t('Intro.settingsIntro')}
        </div>
        </div>`,
      },
      {
        title: t('Global.model'),
        element: '#Model',
        intro: `<div style="display: flex;">
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_Model}  />
        </div>
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-left: 8px;">
        ${t('Intro.modelIntro')}
        </div>
        </div>`,
      },
      {
        title: t('Global.edit'),
        element: '#Edit',
        intro: `<div style="display: flex;">
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_Edit}  />
        </div>
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-left: 8px;">
        ${t('Intro.editIntro')}
        </div>
        </div>`,
      },
      {
        title: t('Global.delete'),
        element: '#Delete',
        intro: `<div style="display: flex;">
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_Delete}  />
        </div>
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-left: 8px;">
        ${t('Intro.dowloadIntro')}
        </div>
        </div>`,
      },
      {
        title: `${t('Global.download')}/${t('Global.save')}`,
        element: '#Download',
        intro: `<div style="display: flex;">
        <div style="width: 30%; display: flex; justify-content: center;align-items: center;"">
        <img src=${Intro_Download}  />
        </div>
        <div style="display: flex; justify-content: center; width: 70%; align-items: center; padding-left: 8px;">
        ${t('Intro.dowloadIntro')}
        </div>
        </div>`,
      },
    ]
    // modifies the `steps` state based on whether GIM is connected, removing the 'GIMmanager' step if connected, and additionally removing the 'My Projects' step if the user is not authenticated. Then return the setSteps
    if (isGimConnected) {
      const tempSteps = [...allSteps]
      const GIMmanagerIndex = tempSteps?.findIndex(
        (ele) => ele?.title === 'GIMmanager'
      )
      tempSteps.splice(GIMmanagerIndex, 1)
      if (!isAuthenticated) {
        const MyProjectIndex = tempSteps?.findIndex(
          (ele) => ele?.title === t('Intro.myProjects')
        )
        tempSteps.splice(MyProjectIndex, 1)
      }
      setSteps(tempSteps)
    } else {
      const tempSteps = [...allSteps]
      if (!isAuthenticated) {
        const MyProjectIndex = tempSteps?.findIndex(
          (ele) => ele?.title === t('Intro.myProjects')
        )
        tempSteps.splice(MyProjectIndex, 1)
      }
      setSteps(tempSteps)
    }

    return () => {
      setSteps([])
    }
  }, [i18n.language, t, isGimConnected, isAuthenticated])

  // Need to update steps as some steps not loaded while intro js started
  const onBeforeChangeStep = async (e, el) => {
    let ElementId = el?.getAttribute('id')

    if (e === 0) {
      // if (document.querySelector('#basic-nav-dropdown') !== null) {
      //   await document.querySelector('#basic-nav-dropdown').click()
      //   await document.querySelector('#basic-nav-dropdown').click()
      // }

      if (isMobileView) {
        setTimeout(async () => {
          await document
            .querySelector('.toggle.navbar-toggler.collapsed')
            .click()
        }, 100)
      }

      if (document.querySelector('#basic-nav-dropdown') !== null) {
        await document.querySelector('#basic-nav-dropdown').click()
        await document.querySelector('#basic-nav-dropdown').click()
        // await StepsRef.current.updateStepElement(3)
        await StepsRef.current.updateStepElement(e)
        // return true
      }
      await StepsRef.current.updateStepElement(3)
    }

    if (ElementId === 'GimStore') {
      // Click on gimstore to show the project list
      if (document.querySelector('#GimStore') !== null) {
        await document.querySelector('#GimStore').click()
        // await document.querySelector('#GIMStore-App').click()
        // await document.querySelector('#GimStore').click()
      }
    }
    // Click on gimstore to show the project list
    // if (e === 0) {
    //   if (document.querySelector('#GimStore') !== null) {
    //     // await document.querySelector('#GimStore').click()
    //   }

    //   await StepsRef.current.updateStepElement(3)
    // }

    // if (e === 3) {
    if (ElementId === 'my-projects') {
      if (document.querySelector('#basic-nav-dropdown') !== null) {
        await document.querySelector('#basic-nav-dropdown').click()
        // await StepsRef.current.updateStepElement(3)
        await StepsRef.current.updateStepElement(e)
        return true
      } else {
        // await StepsRef.current.updateStepElement(3)
        // await StepsRef.current.updateStepElement(4)
        // await StepsRef.current.updateStepElement(5)
        // await StepsRef.current.updateStepElement(6)
      }
    }
    // if (e === 4) {
    //   await StepsRef.current.updateStepElement(4)
    //   await StepsRef.current.updateStepElement(5)
    // }
    // if (e === 5) {
    if (ElementId === 'ProjectList') {
      await StepsRef.current.updateStepElement(e)
      // If project not clicked by user then click on first project to get object list
      if (document.querySelector('.tree-view-container') === null) {
        if (
          document.querySelector(
            '#ProjectList .project-title'
            // '#ProjectList .d-flex.align-items-center.px-3:nth-child(1) div:nth-child(1)'
          ) !== null
        ) {
          await document
            .querySelector(
              '#ProjectList .project-title'
              // '#ProjectList .d-flex.align-items-center.px-3:nth-child(1) div:nth-child(1)'
            )
            .click()
          await StepsRef?.current?.updateStepElement(e + 1)
          // await StepsRef.current.updateStepElement(6)
        }
      }
      await StepsRef.current.updateStepElement(e + 1)
    }
    if (ElementId === 'projectExternalLink') {
      await StepsRef.current.updateStepElement(e)
      // await StepsRef.current.updateStepElement(e + 1)
      // await StepsRef.current.updateStepElement(e + 2)
    }

    if (e === 6) {
      // Object list
      await StepsRef.current.updateStepElement(6)
      await StepsRef.current.updateStepElement(e + 1)
      // Object external link
      // await StepsRef.current.updateStepElement(7)
      // await StepsRef.current.updateStepElement(8)
      // Action buttons
      await StepsRef.current.updateStepElement(9)
      await StepsRef.current.updateStepElement(10)
      await StepsRef.current.updateStepElement(11)
      await StepsRef.current.updateStepElement(12)
      if (steps?.length > 13) {
        await StepsRef.current.updateStepElement(13)
      }
      if (steps?.length > 14) {
        await StepsRef.current.updateStepElement(14)
      }
      // return true
    }
    if (e === 7) {
      await StepsRef.current.updateStepElement(7)
      // await StepsRef.current.updateStepElement(8)
      // return e + 1
    }
    // gim manager button
    if (e === 8) {
      await StepsRef.current.updateStepElement(8)
      if (document.querySelector('#InstallGIMmanager') !== null) {
        StepsRef.current.updateStepElement(9)
      } else {
        // if (StepsRef.current.introJs._direction === 'forward') {
        //   StepsRef.current.introJs._currentStep = 9
        // }
      }
    }
    // steps.map(async (ele, i) => {
    //   if (i >= e) {
    //     console.log('stpes>>>', e, i)
    //     await StepsRef.current.updateStepElement(i)
    //   }
    // })
  }

  return (
    <AppContext.Provider
      value={{
        appContext,
        setAppContext,
      }}
    >
      {document.querySelector('#GimStore') !== null && (
        <Steps
          enabled={appContext.showIntro}
          steps={steps}
          initialStep={initialStep}
          ref={StepsRef}
          onExit={() =>
            setAppContext((prevContext) => ({
              ...prevContext,
              showIntro: false,
            }))
          }
          onBeforeChange={onBeforeChangeStep}
          scrollToElement={true}
          options={{
            hideNext: false,
            hidePrev: true,
            exitOnEsc: true,
            prevLabel: t('Global.back'),
            nextLabel: t('Global.next'),
            doneLabel: t('Global.done'),
            scrollToElement: true,
            scrollPadding: 10,
            // overlayOpacity: 0.1,
            // disableInteraction: false,
            // exitOnOverlayClick: false,
            // showStepNumbers: true,
            // showBullets: false,
            showButtons: true,
            // showProgress: true,
          }}
        />
      )}
      <I18nextProvider i18n={i18n}>
        <BrowserRouter
        // forceRefresh={true}
        >
          <Switch>
            <ThemedRoute
              path={`${routePaths.iFrame}/:lang/:projectId/:objectId?`}
              exact
              component={Iframe}
            />
            <ThemedRoute
              path={`${routePaths.iFrameMap}`}
              exact
              component={IframeMap}
            />
            <ThemedRoute path={routePaths.root} exact component={Home} />
            <ThemedRoute path={`${routePaths.home}`} exact component={Home} />
            <ThemedRoute
              path={`${routePaths.aboutUs}`}
              exact
              component={AboutUs}
            />
            <ThemedRoute
              path={`${routePaths.contact}`}
              exact
              component={Contact}
            />
            <ThemedRoute
              path={`${routePaths.vacancy}`}
              exact
              component={Vacancy}
            />
            <ThemedRoute
              path={`${routePaths.werkenBijGim}`}
              exact
              component={WerkenBijGIM}
            />
            <ThemedRoute
              path={`${routePaths.internshipMarketing}`}
              exact
              component={InternshipMarketing}
            />
            <ThemedRoute
              path={`${routePaths.internshipDevelopment}`}
              exact
              component={InternshipDevelopment}
            />
            <ThemedRoute
              path={`${routePaths.privacyPolicy}`}
              exact
              component={PrivacyPolicy}
            />
            <ThemedRoute path={`${routePaths.faqs}`} exact component={FAQs} />
            {/* <ThemedRoute path={'/trail'} component={Trail} /> */}
            <ThemedRoute path={routePaths.veryfyProject} component={Verify} />
            {/* <ThemedRoute path={routePaths.customers} component={Customer} /> */}
            {/* <ThemedRoute
              path={`${routePaths.store}/:projectId?/:objectId?`}
              exact
              component={Gim}
            />
            <ThemedRoute
              path={`${routePaths.pro}/:projectId?/:objectId?`}
              exact
              component={Gim}
            /> */}
            <ThemedRoute
              path={routePaths.storeInfo}
              exact
              component={StoreInfo}
            />
            <ThemedRoute
              path={routePaths.storeTech}
              exact
              component={StoreTech}
            />
            <ThemedRoute path={routePaths.proInfo} exact component={ProInfo} />
            <ThemedRoute path={routePaths.proTech} exact component={ProTech} />
            <ThemedRoute
              path={`${routePaths.transferApp}/:projectId?`}
              exact
              component={Gim}
            />
            <ThemedRoute
              path={`${routePaths.storeApp}/:projectId?/:objectId?`}
              exact
              component={Gim}
            />
            <ThemedRoute
              path={`${routePaths.proApp}/:projectId?/:objectId?`}
              exact
              component={Gim}
            />
            <ThemedRoute
              path={`${routePaths.mapsApp}`}
              exact
              component={GimMaps}
            />
            <ThemedRoute
              path={routePaths.mapsInfo}
              exact
              component={MapsInfo}
            />
            <ThemedRoute
              path={routePaths.mapsTech}
              exact
              component={MapsTech}
            />
            {/* <ThemedRoute
              path={routePaths.solution}
              exact
              component={Solution}
            /> */}
            {/* <ThemedRoute path={routePaths.solutionDemo} component={Demo} /> */}
            {/* <ThemedRoute
              path={`${routePaths.solutionPreFund}`}
              component={PreFund}
            />
            <ThemedRoute
              path={`${routePaths.solutionIncreaseTheProgress}`}
              component={Vote}
            /> */}
            {/* <ThemedRoute
              path={routePaths.downloadDesktop}
              component={DownloadExe}
            /> */}
            {/* <ThemedRoute
              path={routePaths.linkedIn}
              exact
              component={LinkedIn}
            /> */}
            <ThemedRoute
              path={routePaths.testResult}
              exact
              component={TestResult}
            />
            <AuthAdminRoute
              path={routePaths.invoiceList}
              component={InvoiceList}
              isAuthenticated={isAuthenticated}
              isAdmin={isAdmin}
              userRole={userRole}
              exact
            />
            <AuthAdminRoute
              path={routePaths.projectOverview}
              component={ProjectOverview}
              isAuthenticated={isAuthenticated}
              isAdmin={isAdmin}
              userRole={userRole}
              exact
            />
            {/* <AuthRoute
              path={routePaths.safe}
              exact
              component={Safe}
              isAuthenticated={isAuthenticated}
            /> */}
            <AuthRoute
              path={routePaths.projects}
              exact
              component={Projects}
              isAuthenticated={isAuthenticated}
            />

            {/* Implement add/edit projects in my project so not needed now */}
            {/* <AuthRoute
              path={routePaths.addProject}
              component={AddProjects}
              isAuthenticated={isAuthenticated}
            />
            <AuthRoute
              path={routePaths.editProject}
              component={EditProjects}
              isAuthenticated={isAuthenticated}
            /> */}

            <ThemedRoute path="*" component={NotFound} />
          </Switch>
        </BrowserRouter>
      </I18nextProvider>
    </AppContext.Provider>
  )
}

export default App
